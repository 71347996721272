import { produce } from 'immer'
import type { StateCreator } from 'zustand'

import { getActiveContractTemplate } from '@/open-web/utils/purchaseFlowUtils'
import type { CampaignConfigurationEntry } from '@/shared/contentful/types'
import type {
  EnrichedContractProduct,
  EnrichedContractTemplate,
} from '@/shared/services/campaignDataResolver'
import { UnknownGlobalApiError } from '@/shared/utils/errorClasses'

import { middleware } from '../middleware'
import type { Store } from '../types'

export type SelectedAddons = Record<string, SelectedAddon>

export type SelectedAddon = { value: number | null }

const customerTypes = ['private', 'enterprise'] as const
export type CustomerType = (typeof customerTypes)[number]

export type SelectedContract = {
  contractTemplate?: EnrichedContractTemplate
  contractProduct?: EnrichedContractProduct
  campaignConfiguration?: CampaignConfigurationEntry
  selectedAddons: SelectedAddons
}

type SelectedContractState = Record<CustomerType, SelectedContract>

type SelectedContractActions = {
  selectContract: (contractTemplate: EnrichedContractTemplate, customerType: CustomerType) => void
  setSelectedAddon: (
    addonType: string,
    selectedAddon: SelectedAddon,
    customerType: CustomerType,
  ) => void
  setSelectedAddons: (selectedAddons: SelectedAddons, customerType: CustomerType) => void
  resetSelectedData: () => void
  resetSelectedAddon: (addonType: string, customerType: CustomerType) => void
  setContractProduct: (contractProduct: EnrichedContractProduct, customerType: CustomerType) => void
  setCampaignConfiguration: (
    campaignConfiguration: CampaignConfigurationEntry,
    customerType: CustomerType,
  ) => void
}

export type SelectedContractStore = {
  selectedContract: SelectedContractState & SelectedContractActions
}

// Good practice to keep it still, even while empty
export const initialState: SelectedContractState = {
  private: { selectedAddons: {} },
  enterprise: { selectedAddons: {} },
}

export const creator: StateCreator<
  Store,
  [['zustand/persist', unknown]],
  [],
  SelectedContractStore
> = (set, get) => ({
  selectedContract: {
    ...initialState,
    selectContract: (contractTemplate, customerType) => {
      set(
        produce(get(), (state: Store) => {
          state.selectedContract[customerType].contractTemplate = contractTemplate
        }),
      )
      get().checkoutFlow.resetCheckout(customerType)
    },
    setSelectedAddons: (selectedAddons, customerType) => {
      set(
        produce(get(), (state: Store) => {
          state.selectedContract[customerType].selectedAddons = selectedAddons
        }),
      )
    },
    setSelectedAddon: (addonType, selectedAddon, customerType) => {
      set(
        produce(get(), (state: Store) => {
          state.selectedContract[customerType].selectedAddons = {
            ...state.selectedContract[customerType].selectedAddons,
            [addonType]: selectedAddon,
          }
        }),
      )
    },
    resetSelectedAddon: (addonType, customerType) => {
      set(
        produce(get(), (state: Store) => {
          if (state.selectedContract[customerType].selectedAddons[addonType]) {
            state.selectedContract[customerType].selectedAddons[addonType] = { value: null }
          }
        }),
      )
    },
    resetSelectedData: () => {
      set(
        produce(get(), (state: Store) => {
          customerTypes.forEach((key) => {
            state.selectedContract[key].contractProduct = undefined
            state.selectedContract[key].contractTemplate = undefined
            state.selectedContract[key].campaignConfiguration = undefined
            state.selectedContract[key].selectedAddons = {}
          })
        }),
      )
    },
    setContractProduct: (contractProduct, customerType) => {
      set(
        produce(get(), (state: Store) => {
          state.selectedContract[customerType].contractProduct = contractProduct
        }),
      )
    },
    setCampaignConfiguration: (campaignConfiguration, customerType) => {
      set(
        produce(get(), (state: Store) => {
          state.selectedContract[customerType].campaignConfiguration = campaignConfiguration
        }),
      )
      get().checkoutFlow.resetCheckout(customerType)
    },
  },
})

export const createSelectedContractSlice = middleware(creator, (prevState, _set, get, _store) => {
  const prevProps = prevState.housingInfo
  const { estimatedConsumption } = get().housingInfo

  const { selectContract, setSelectedAddons } = get().selectedContract

  const { contractProduct, contractTemplate, campaignConfiguration } =
    get().selectedContract.private

  if (contractTemplate?.customerType !== 'PRIVATE') {
    // Below logic is only related to B2C customers
    return
  }

  //Sweden market feature
  //If there is two contracts with same type and duration, then we need to select correct one base on estimatedConsumption
  const isContractTemplateMightChange =
    contractProduct?.contractTemplates &&
    contractProduct?.contractTemplates?.length > 1 &&
    contractTemplate &&
    campaignConfiguration &&
    prevProps.estimatedConsumption !== estimatedConsumption

  try {
    if (isContractTemplateMightChange) {
      const contractTemplateForConsumption = getActiveContractTemplate(
        contractProduct,
        campaignConfiguration.campaignId,
        estimatedConsumption,
      )

      if (contractTemplateForConsumption?.tariffNo !== contractTemplate?.tariffNo) {
        selectContract(contractTemplateForConsumption, 'private')
        //Addons might differ between villa and apartment contractTemplates so we have to clean them up
        setSelectedAddons({}, 'private')
      }
    }
  } catch (error) {
    //Should never happened but...
    const message =
      error instanceof Error
        ? error.message
        : typeof error === 'string'
          ? error
          : 'Something went wrong in selectedContractSlice'
    new UnknownGlobalApiError(message)
  }
})
